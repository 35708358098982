import React from "react"
import style from "./footer.module.scss"

const Footer = () => (
  <div className={style.footer}>
    <div className={style.contact} id="footer">
      <div className={style.listItem}>
        <b>EUROTERM PLUS</b>
      </div>
      <div className={style.listItem}>
        <b>Adresa:</b> Hajduk Veljkova 8 34300 Aranđelovac
      </div>
      <div className={style.listItem}>
        <b>Telefon:</b>
        <a href="tel:+38162203083"> +381 62 203 083</a>
      </div>

      <div className={style.listItem}>
        <b>Email:</b>
        <a href="mailto:eurotermplus@gmail.com"> eurotermplus@gmail.com</a>{" "}
      </div>
    </div>

    <div className={style.contact}>
      <div className={style.listItem}>
        <b>Izvođenje:</b>
        <a href="tel:+38162203083"> Ivan Mladenović + 381 62 203 083</a>
      </div>
      <div className={style.listItem}>
        <b>Projektovanje:</b>
        <a href="tel:+381603322315"> Vesna Slavić +381 60 33 22 315</a>
      </div>
      <div className={style.listItem}>
        <b>PIB:</b> 111766672
      </div>
      <div className={style.listItem}>
        <b>MB:</b> 65649553
      </div>
    </div>
  </div>
)

export default Footer
