import React from "react"
import styles from "./section.module.scss"
import Gallery from "../gallery/Gallery"

const Section = ({
  className,
  id,
  title,
  description,
  images,
  backgroundImage,
}) => {
  return (
    <div
      className={`${className || ""} ${styles.section}`}
      id={id}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={images ? styles.left : styles.padding}>
        <div className={styles.content}>
          <h2>{title}</h2>
          {description}
        </div>
      </div>
      {images && (
        <div className={styles.right}>
          <Gallery images={images} />
        </div>
      )}
    </div>
  )
}

export default Section
