import React from "react"
import styles from "./boxbar.module.scss"
import iconStyles from "../box/icons.module.scss"

import Box from "../box/Box"

const Boxbar = () => (
  <div className={styles.boxbar}>
    <Box
      title="O NAMA"
      className={styles.firstBox}
      href="/#about"
      iconClassName={iconStyles.ciconHeating}
    />

    <Box
      title="USLUGE"
      className={styles.secondBox}
      href="/#services"
      iconClassName={iconStyles.ciconCooling}
    />

    <Box
      title="GALERIJA"
      className={styles.thirdBox}
      href="/#gallery"
      iconClassName={iconStyles.ciconAirQuality}
    />
  </div>
)

export default Boxbar
