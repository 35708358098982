import React from "react"
import { StaticQuery, graphql } from "gatsby"
import GatsbyGallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

const Gallery = ({ data }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const images = data.allFile.edges.map(
          ({ node }) => node.childImageSharp
        )
        return (
          <div style={{ margin: "100px 6%" }}>
            <GatsbyGallery images={images} />
          </div>
        )
      }}
    />
  )
}

export const query = graphql`
  query ImagesForGallery {
    allFile(
      filter: { dir: { regex: "/gallery/" } }
      sort: { order: DESC, fields: modifiedTime }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Gallery
