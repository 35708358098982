import React, { Fragment } from "react"
import { Helmet } from "react-helmet"

import "../../src/global-styles.scss"

import Navbar from "../components/navbar/Navbar"
import Topbar from "../components/topbar/Topbar"
import Boxbar from "../components/boxbar/Boxbar"
import Carousel from "../components/carousel/Carousel"
import Section from "../components/section/Section"
import Footer from "../components/footer/Footer"
import sectionStyles from "../components/section/section.module.scss"
import Gallery from "../components/gallery/Gallery"

export default () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="Klimatizacija | Grejanje | Ventilacija | Vodovodne
          instalacije"
        ></meta>
        <meta
          name="google-site-verification"
          content="XiXWqT1craBFhfG438iVGHRyKtEw4NUX4mn87qxEb0Y"
        />
        <title>
          Euroterm Plus - Klimatizacija | Grejanje | Ventilacija | Vodovodne
          instalacije | Projektovanje
        </title>
      </Helmet>
      <div className="sticky">
        <Topbar />
        <Navbar />
      </div>
      <Carousel />
      <Boxbar />
      <Section
        id="about"
        backgroundImage="bckg3.jpg"
        title="O Nama"
        description={
          <div className="about-section">
            <p>
              EUROTERM PLUS je preduzeće koje se bavi projektovanjem,izvođenjem
              i održavanjem termotehničkih instalacija.
            </p>
            <p>
              Naš tim poseduje dugogodišnje iskustvo u ovoj oblasti sa objektima
              raznih namena širom regiona, kao što su stambeni,poslovni,
              industrijski, ugostiteljski, sportski objekti,bazeni, SPA i
              Wellness centri...
            </p>
            <p>
              Projektujemo i izvodimo instalacije
              klimatizacije,grejanja,hlađenja i ventilacije,grejanja sanitarne,
              bazenske vode...
            </p>
            <p>
              Pored  instalacija kao što su kotlovi na pelet,gas,čvrsto gorivo i
              struju naša firma je specijalizovana i za savremene
              biološko-ekološke sisteme  - korišćenje raspoloživih obnovljivih
              prirodnih izvora energije kao na primer za sisteme topotnih pumpi,
              pasivna grejanja i hlađenja... Takođe bavimo se i korišćenjem
              raspoložive otpadne rashladne,grejne energije iz raznih objekata,
              tehnoloških procesa u industriji koji nekada osim uštede energije
              mogu poboljšati/ubrzati i sam tehnološki proces čime se postižu i
              benefiti u samoj proizvodnji.
            </p>
            <p>
              Naš cilj je maksimalno poboljšanje energetske efikasnosti objekata
              sa višestrukim uštedama energije, zdraviji boravišni prostor sa
              optimalnim mikroklimatskim uslovima,uz smanjenje zagađenja
              okoline.
            </p>
            <p>
              Projektujemo i ugrađujemo širok opseg instalacija,od
              najjednostavnijih kao što su radijatori pa do zahtevnijih kao što
              su  podna i zidna grejanja i hlađenja, plafonska hlađenja,
              facoili, kasetne, kanalske jedinice,rekuperatorske jedinice,klima
              komore, vazdušni kanali i distributeri... Pored obezbeđivanja
              vrhunskih mikroklimatskih uslova u prostoru, naš tim poseduje i
              dugogodišnje iskustvo u nalaženju specifičnih enterijerskih
              rešenja koja se uklapaju sa zahtevnim arhitektonskim rešenjima.
            </p>
          </div>
        }
      />

      <div id="services" />
      <div
        className="section-with-image consulting"
        data-sal="zoom-in"
        data-sal-delay="100"
        data-sal-easing="ease-out-back"
      >
        <div className="image-container">
          <img src="consulting.png" alt="consulting" />
        </div>

        <div className={sectionStyles.content}>
          <h2>Projektovanje i konsultacije</h2>
          <div>
            <p>
              Izrada projekata iz domena termotehnike, revizija
              projekata, konsultantske aktivnosti.
            </p>
            <p>
              Naš posao je da vam predložimo odgovarajuće tehničko rešenje koje
              će pre svega da ispuni Vaše zahteve i potrebe,  ali i da bude
              optimalno u investiciono-eksploatacionom smislu.
            </p>
            <p>Takođe težimo boljim ekološkim i zdravstvenim uslovima.</p>
            <p>
              Naše konsultantske aktivnosti pored termotehničkih instalacija
              obuhvataju i savete vezane za arhitektonsko-građevinsko
              poboljšanje energetske efikasnosti objekta i ugodnosti boravka u
              prostoru kao i zdravstvenih aspekata kako termotehničkih sistema
              tako i građevinskih materijala.
            </p>
          </div>
        </div>
      </div>

      <div id="work" />
      <div
        className="section-with-image consulting consulting--image-right"
        data-sal="zoom-in"
        data-sal-delay="100"
        data-sal-easing="ease-out-back"
      >
        <div className={sectionStyles.content}>
          <h2>Izvođenje i konsultacije</h2>
          <div>
            <p>
              Naš posao je kvalitetno izvođenje termotehničkih instalacija, kao
              i vodovodnih i kanalizacionih.
            </p>
            <p>
              Pouzdanost i kvalitet su rezultat našeg velikog iskustva u raznim
              domenima termotehnike, od manjih stambenih objekata do većih
              industrijskih.
            </p>
            <p>
              Naše iskustvo i znanje daju nam  mogućnost prepoznavanja kako
              kvalitetnih rešenja u projektima koje posedujete, tako i
              mogućnosti poboljšanja postojećih projektnih rešenja a sve u cilju
              većeg zadovoljstva korisnika izvedenim instalacijama.
            </p>
          </div>
        </div>
        <div className="image-container">
          <img src="izvodjenje.jpg" alt="izvodjenje" />
        </div>
      </div>

      <div id="gallery" />
      <Gallery />

      <Footer />
    </Fragment>
  )
}
