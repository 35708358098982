import React from "react"
import topbarStyles from "./topbar.module.scss"
import logo from "./img/logo.png"

const Topbar = () => (
  <div className={topbarStyles.topbar} id="top">
    <div className="content-container">
      <div className={topbarStyles.container}>
        <div className={topbarStyles.topbarLogo}>
          <img src={logo} alt="logo" />
        </div>
        <ul>
          <li className={topbarStyles.listItem}>KLIMATIZACIJA</li>
          <li className={topbarStyles.listItem}>GREJANJE</li>
          <li className={topbarStyles.listItem}>HLAĐENJE</li>
          <li className={topbarStyles.listItem}>VENTILACIJA</li>
          <li className={topbarStyles.listItem}>VODOVODNE INSTALACIJE</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Topbar
