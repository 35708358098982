import React from "react"
import PropTypes from "prop-types"

import boxStyles from "./box.module.scss"
import iconStyles from './icons.module.scss'
import Button from "../button/Button"

const Box = ({ className, title, href, iconClassName }) => (
  <div className={`${className} ${boxStyles.box}`}>
    <div className={boxStyles.boxTitle}><i className={`${iconStyles.icon} ${iconClassName}`}></i>{title}</div>
    <Button href={href}/>
  </div>
)

Box.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Box
