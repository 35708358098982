import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./button.module.scss"

const Button = ({ href }) => {
  return (
    <button>
      <AnchorLink stripHash to={href}>
        Saznaj više
      </AnchorLink>
    </button>
  )
}

export default Button
