import React, { useState } from "react"
import navbarStyles from "./navbar.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const NavLink = ({ to, children, onClick }) => (
  <li className={navbarStyles.navbarLink} onClick={onClick}>
    <AnchorLink
      activeClassName={navbarStyles.active}
      to={to}
      className="stripped"
      stripHash
    >
      {children}
    </AnchorLink>
  </li>
)

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <div className={navbarStyles.navbar}>
        <div className={navbarStyles.desktop}>
          <div className="content-container">
            <NavLink to="/#about">O nama</NavLink>
            <div className="dropdown">
              <NavLink to="/#services">USLUGE</NavLink>
              <div className="dropdown-content">
                <NavLink to="/#services">PROJEKTOVANJE I KONSULTACIJE</NavLink>
                <NavLink to="/#work">IZVOĐENJE I KONSULTACIJE</NavLink>
                <NavLink to="/#services" onClick={e => e.preventDefault()}>
                  ODRŽAVANJE TERMOTEHNIČKIH INSTALACIJA 
                </NavLink>
              </div>
            </div>

            <NavLink to="/#gallery">GALERIJA</NavLink>
            <NavLink to="/#footer">kontakt</NavLink>
          </div>
        </div>

        <div className={navbarStyles.mobile}>
          <img src="hamburger-icon.png" alt="hamburger" onClick={toggle} />
          {isOpen && (
            <div className={navbarStyles.mobileContainer}>
              <div className={navbarStyles.linkStyle}>
                <NavLink to="/#about" onClick={() => toggle()}>
                  O nama
                </NavLink>
                <NavLink to="/#services" onClick={() => toggle()}>
                  Usluge
                </NavLink>
                <NavLink to="/#gallery" onClick={() => toggle()}>
                  Galerija
                </NavLink>
                <NavLink to="/#footer" onClick={() => toggle()}>
                  kontakt
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Navbar
