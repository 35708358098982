import React, { useState, useEffect } from "react"

import style from "./carousel.module.scss"
import { graphql, useStaticQuery } from "gatsby"

export const squaresImage = graphql`
  fragment squaresImage on File {
    sharp: childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const Carousel = () => {
  const { image1, image2, image3 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "slide1.jpg" }) {
        ...squaresImage
      }
      image2: file(relativePath: { eq: "slide2.jpg" }) {
        ...squaresImage
      }
      image3: file(relativePath: { eq: "slide3.jpg" }) {
        ...squaresImage
      }
    }
  `)

  const images = [image1.sharp.fluid, image2.sharp.fluid, image3.sharp.fluid]

  const [state, setState] = useState({
    activeIndex: 0,
  })

  const { activeIndex } = state

  const nextSlide = () => {
    if (activeIndex === images.length - 1) {
      return setState({
        activeIndex: 0,
      })
    }

    setState({
      activeIndex: activeIndex + 1,
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide()
    }, 8000)
    return () => clearInterval(interval)
  })

  return (
    <div className={style.carouselContainer}>
      {images.map((image, index) => (
        <div
          key={index}
          id={index}
          className={
            index === activeIndex
              ? `${style.carousel} ${style.active}`
              : `${style.carousel}`
          }
        >
          <div
            style={{
              backgroundImage: `url(${image.src})`,
            }}
            className={style.carouselImg}
          ></div>
        </div>
      ))}
    </div>
  )
}

export default Carousel
